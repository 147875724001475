<template>
  <div class="min-h-screen">
    <div class="p-6 md:p-12">
      <div class="max-w-4xl mx-auto">
        <!-- Header -->
        <h1 class="text-3xl md:text-4xl font-display font-bold text-slate-100 mb-8">
          DMCA and Takedowns Policy
        </h1>

        <!-- Introduction -->
        <p class="text-slate-300 mb-8">
          <span class="font-semibold text-slate-200">Kids Tell Tales sp. z o.o.</span> respects the intellectual property rights of others.
          If you believe that material located on the Services violates your copyright or other intellectual property rights,
          please send a notice of claimed infringement to <a href="mailto:support@kidstelltales.com" class="text-blue-400 hover:text-blue-300">support@kidstelltales.com</a> with
          the subject "Takedown Request".
        </p>

        <!-- Takedown Notice Requirements -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
            The takedown notice should include:
          </h2>
          <ol class="list-decimal list-outside ml-6 text-slate-300 space-y-4">
            <li>Your personal and contact data: name, e-mail address, postal address, telephone number</li>
            <li>Identification and description of the copyrighted work you believe to have been infringed or, if the claim involves multiple works, a representative list of such works</li>
            <li>Identification and description of the material you believe to be infringing in a sufficiently precise and detailed manner to allow us to locate that material (i.g. reference, link)</li>
            <li>A statement that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner, its agent, or the law</li>
            <li>A statement, signed under the penalty of perjury, that the information in the notice is accurate and you are authorized to act on behalf of the copyright owner</li>
            <li>If the copyright owner's rights arise under the laws of a country other than the United States, please identify the country</li>
            <li>Your signature (electronics or physical) or a person authorized to act on your behalf</li>
          </ol>
        </section>

        <!-- Important Warnings -->
        <section class="mb-12 space-y-4">
          <div class="p-4 bg-yellow-600/10 border border-yellow-500/20 rounded-lg">
            <p class="text-yellow-300 font-medium">
              The DMCA requires that you swear to the facts in your copyright complaint under penalty of perjury.
              It is a federal crime to intentionally lie in a sworn declaration.
            </p>
          </div>

          <div class="p-4 bg-yellow-600/10 border border-yellow-500/20 rounded-lg">
            <p class="text-yellow-300 font-medium">
              Be aware that if you knowingly misrepresent that material or activity on the Services is infringing your copyright,
              you may be held liable for damages (including costs and attorneys' fees) under Section 512(f) of the DMCA.
            </p>
          </div>
        </section>

        <!-- Physical Address -->
        <section class="mb-12">
          <p class="text-slate-300 mb-4">You may also send takedown notices to the following address:</p>
          <address class="text-slate-300 not-italic">
            Kids Tell Tales sp. z o.o.,<br>
            Stelmachów 46b/2,<br>
            31-341 Kraków, Poland
          </address>
        </section>

        <!-- Our Rights -->
        <section class="mb-12">
          <p class="text-slate-300 mb-4">Upon receipt of a takedown notice that complies with the foregoing, we reserve the right to:</p>
          <ol class="list-decimal list-outside ml-6 text-slate-300 space-y-4">
            <li>remove or disable access to the accused material or disable any links to the material</li>
            <li>notify the party accused of infringement that we have removed or disabled access to the identified material</li>
            <li>terminate access to and use of the Services for any user who engages in repeated acts of infringement</li>
          </ol>
        </section>

        <!-- Counter Notification -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
            Counter-Notification Process
          </h2>
          <p class="text-slate-300 mb-4">
            If you believe that your material was removed or access to it was disabled by mistake or misidentification,
            you may file a counter-notification with us by submitting a written notification to our copyright agent designated above.
            Such notification must include substantially the following:
          </p>
          <ol class="list-decimal list-outside ml-6 text-slate-300 space-y-4">
            <li>Your personal and contact data: name, e-mail address, postal address, telephone number</li>
            <li>An identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access disabled</li>
            <li>A statement, signed under penalty of perjury by you, that you have a good faith belief that the material identified above was removed or disabled as a result of a mistake or misidentification</li>
            <li>A statement that you will consent to the jurisdiction of the Federal District Court for the judicial district in which your address is located (or if you reside outside the United States for any judicial district in which the Services may be found) and that you will accept service from the person (or an agent of that person) who provided us with the complaint at issue</li>
            <li>Your signature (electronics or physical)</li>
          </ol>
        </section>

        <!-- Warning About Misrepresentation -->
        <div class="mb-12 p-4 bg-yellow-600/10 border border-yellow-500/20 rounded-lg">
          <p class="text-yellow-300 font-medium">
            Be aware that if you knowingly materially misrepresent that material or activity on the Services was removed
            or disabled by mistake or misidentification, you may be held liable for damages (including costs and attorney's
            and fees) under Section 512(f) of the DMCA.
          </p>
        </div>

        <p class="text-slate-300 mb-12">
          The DMCA allows us to restore the removed content within 10-14 business days unless the complaining party
          initiates a court action against you during that time and notifies us of the same.
        </p>


        <!-- DMCA Forms -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-8">
            DMCA Takedown Notice Template
          </h2>

          <!-- Email Template -->
          <div class="rounded-lg border border-slate-700 overflow-hidden mb-12">
            <div class="bg-slate-800 px-6 py-3 border-b border-slate-700 flex items-center justify-between">
              <h3 class="text-lg font-display font-semibold text-slate-100">Email Template</h3>
              <button
                  @click="copyToClipboard('takedownTemplate')"
                  class="px-3 py-1 text-sm bg-slate-700 hover:bg-slate-600 text-slate-300 rounded-lg transition-colors flex items-center space-x-2"
              >
                <span>Copy template</span>
              </button>
            </div>
            <div class="p-6 bg-slate-800/50">
              <pre id="takedownTemplate" class="whitespace-pre-wrap text-slate-300 font-mono text-sm">Subject: DMCA Takedown Request

DMCA TAKEDOWN NOTICE

Claimant Details:
----------------
Full name: [Your full name]
Email address: [Your email address]
Postal address: [Your postal address]
Phone number: [Your phone number]

Description of the copyrighted work:
----------------------------------
[Provide description of your copyrighted work. If claim involves multiple works, provide a representative list]

Description of the infringing material:
------------------------------------
[Provide detailed identification of the infringing material, including reference or link]

Good Faith Statement:
------------------
I have a good faith belief that use of the copyrighted material described above is not authorized by the copyright owner, its agent, or the law.

Declaration:
----------
I declare under penalty of perjury that the information in this notice is accurate and that I am authorized to act on behalf of the copyright owner.

Country of Copyright:
------------------
The copyright rights arise under the laws of: [Specify United States or other country]

Signature:
---------
[Your signature]
Date: [Current date]</pre>
            </div>
          </div>

          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-8">
            DMCA Counter-Notification Template
          </h2>

          <div class="rounded-lg border border-slate-700 overflow-hidden">
            <div class="bg-slate-800 px-6 py-3 border-b border-slate-700 flex items-center justify-between">
              <h3 class="text-lg font-display font-semibold text-slate-100">Email Template</h3>
              <button
                  @click="copyToClipboard('counterNoticeTemplate')"
                  class="px-3 py-1 text-sm bg-slate-700 hover:bg-slate-600 text-slate-300 rounded-lg transition-colors flex items-center space-x-2"
              >
                <span>Copy template</span>
              </button>
            </div>
            <div class="p-6 bg-slate-800/50">
              <pre id="counterNoticeTemplate" class="whitespace-pre-wrap text-slate-300 font-mono text-sm">Subject: DMCA Counter-Notification

DMCA COUNTER-NOTIFICATION

Respondent Details:
----------------
Full name: [Your full name]
Email address: [Your email address]
Postal address: [Your postal address]
Phone number: [Your phone number]

Identification of Removed Material:
-------------------------------
[Specify the material that was removed or disabled and its location before removal]

Declaration:
----------
I declare under penalty of perjury that I have a good faith belief the material was removed or disabled as a result of mistake or misidentification.

Jurisdiction Consent:
------------------
I consent to the jurisdiction of the Federal District Court for the judicial district in which my address is located (or if I reside outside the United States, any judicial district in which the Services may be found) and agree to accept service from the complainant or their agent.

Signature:
---------
[Your signature]
Date: [Current date]</pre>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useMetaTags } from "@/components/useMetaTags"

const route = useRoute()

onMounted(() => {
  useMetaTags({
    title: 'DMCA and Takedowns Policy',
    path: route.path,
  })
})

const copyToClipboard = async (elementId: string) => {
  const element = document.getElementById(elementId)
  if (element) {
    try {
      await navigator.clipboard.writeText(element.textContent || '')
    } catch (err) {
      console.error('Failed to copy text:', err)
    }
  }
}
</script>