<template>
  <!-- Story Demo Section -->
  <div class="relative py-4">
    <div ref="demoContainer" class="max-w-3xl mx-auto">
      <!-- Demo Setup Screen -->
      <div
           ref="storyForm"
           class="space-y-8 backdrop-blur p-6"
           :class="{ 'opacity-30 pointer-events-none': demoStep == 'story' }"
      >
        <!-- Story Prompt -->
        <div class="space-y-3">
          <label class="block text-lg font-medium text-slate-200">What's your story about?</label>
          <div class="typing-container w-full px-4 py-3 rounded-lg bg-slate-800/50 border border-slate-600/50
          text-slate-100 cursor-not-allowed opacity-75 min-h-[75px]">
            <span class="whitespace-pre-wrap">{{ currentStoryPrompt }}</span>
            <span v-if="!isTypingDone" class="typing-cursor" aria-hidden="true"></span>
          </div>
        </div>

        <!-- Age Band Selection -->
        <div class="space-y-3">
          <label class="block text-lg font-medium text-slate-200">Who is this story for?</label>
          <div class="grid grid-cols-3 gap-3">
            <button
                v-for="age in ['3-4', '5-7', '8+']"
                :key="age"
                @click="ageBand = age"
                :disabled="demoStep == 'story'"
                class="relative p-4 rounded-lg transition-all duration-200 group"
                :class="[
                ageBand === age
                  ? 'bg-blue-600/20 border-blue-500/50'
                  : 'bg-slate-800/50 border-slate-600/50 hover:border-blue-500/30',
                'border'
              ]"
            >
              <div class="flex flex-col items-center">
                <span class="text-3xl mb-2 transition-transform duration-200 group-hover:scale-110">{{
                    ageEmoji(age)
                  }}</span>
                <span class="text-sm font-medium" :class="ageBand === age ? 'text-blue-400' : 'text-slate-300'">{{
                    age
                  }} years</span>
              </div>
            </button>
          </div>
        </div>

        <!-- Story Length Selection -->
        <div class="space-y-3">
          <label class="block text-lg font-medium text-slate-200">How long should the story be?</label>
          <div class="grid grid-cols-3 gap-3">
            <button
                v-for="(label, value) in {'4': 'Short', '6': 'Medium', '8': 'Long'}"
                :key="value"
                @click="length = value"
                :disabled="demoStep == 'story'"
                class="relative p-4 rounded-lg transition-all duration-200 group"
                :class="[
                length === value
                  ? 'bg-blue-600/20 border-blue-500/50'
                  : 'bg-slate-800/50 border-slate-600/50 hover:border-blue-500/30',
                'border'
              ]"
            >
              <div class="flex flex-col items-center">
                <span class="text-3xl mb-2 transition-transform duration-200 group-hover:scale-110">{{
                    lengthEmoji(value)
                  }}</span>
                <span class="text-sm font-medium"
                      :class="length === value ? 'text-blue-400' : 'text-slate-300'">{{ label }}</span>
              </div>
            </button>
          </div>
        </div>

        <!-- Start Demo Button -->
        <button
            :disabled="demoStep == 'story'"
            @click="startDemo"
            class="w-full px-6 py-4 bg-blue-600 hover:bg-blue-500
                 text-white rounded-lg text-lg font-semibold
                 transition-all duration-200
                 flex items-center justify-center space-x-3 group"
        >
          <Sparkles class="h-5 w-5 group-hover:scale-110 transition-transform"/>
          <span>Begin Your Adventure</span>
          <ArrowRight class="h-5 w-5 group-hover:translate-x-1 transition-transform"/>
        </button>
      </div>

      <!-- Story Content - Appears on top of blurred form -->
      <div v-if="demoStep == 'story'" class="relative">
        <div class="space-y-8 p-6" ref="wholeThing">
          <!-- Story Title -->
          <h1 v-if="currentStoryTitle" class="text-3xl md:text-4xl font-display font-bold text-slate-100">
            {{ currentStoryTitle }}
          </h1>

          <!-- Previous Messages -->
          <div v-for="(answer, index) in demoAnswers"
               :key="index"
               class="space-y-6 animate-fade-in">
            <div class="text-lg text-slate-200 leading-relaxed whitespace-pre-line">
              {{ answer.text }}
            </div>

            <div class="space-y-3">
              <div v-for="option in answer.question"
                   :key="option"
                   :class="[
                     'px-6 py-4 rounded-lg transition-all duration-200',
                     option === answer.answer
                       ? 'bg-blue-600/10 text-blue-400 border border-blue-500/20'
                       : 'bg-slate-800/30 text-slate-500'
                   ]"
              >
                {{ option }}
              </div>
            </div>
          </div>

          <!-- Current Story Section -->
          <div v-if="currentStory"
               ref="latestContent"
               class="space-y-6">
            <div class="text-lg text-slate-200 leading-relaxed whitespace-pre-line">
              {{ currentStory.text }}
            </div>

            <div class="space-y-3">
              <div v-for="option in Object.keys(currentStory.options)"
                   :key="option"
                   @click="selectOption(option)"
                   class="px-6 py-4 rounded-lg transition-all duration-200 cursor-pointer
                          bg-slate-800/50 border border-slate-600/50
                          hover:bg-blue-600/10 hover:border-blue-500/20 hover:text-blue-400"
              >
                {{ option }}
              </div>
            </div>
          </div>

          <!-- Subscription Prompt -->
          <div v-if="demoAnswers.length >= 3"
               ref="latestContent"
               class="mt-8 p-6 bg-blue-600/10 border border-blue-500/20 rounded-lg text-center">
            <h3 class="text-xl font-display font-semibold text-blue-400 mb-2">
              Want to hear what happens next?
            </h3>
            <p class="text-slate-300 mb-4">
              Create an account to continue the adventure and create your own magical stories!
            </p>
            <div class="flex flex-col sm:flex-row gap-4 justify-center">
              <button
                  @click="redirectToPurchase"
                  class="inline-flex items-center justify-center px-6 py-3 bg-blue-600 hover:bg-blue-500
                       text-white rounded-lg font-semibold transition-all duration-200 group">
                <Sparkles class="h-5 w-5 mr-2 group-hover:scale-110 transition-transform"/>
                <span>Start now for just $7.99</span>
                <ArrowRight class="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform"/>
              </button>
              <button
                  @click="resetDemo"
                  class="inline-flex items-center justify-center px-6 py-3
                       border border-slate-600/50 hover:border-slate-500/50
                       text-slate-300 hover:text-white
                       rounded-lg font-semibold transition-all duration-200 group">
                <ArrowLeftCircle class="h-5 w-5 mr-2 group-hover:scale-110 transition-transform"/>
                <span>Try Another Story</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, nextTick, onMounted, ref} from 'vue'
import {useRouter} from 'vue-router'
import {ArrowLeftCircle, ArrowRight, Sparkles} from 'lucide-vue-next'
import {scrollToElement} from '@/utils/scroll'

// Router
const router = useRouter()

// Refs for scroll targets
const demoContainer = ref<HTMLElement | null>(null)
const storyForm = ref<HTMLElement | null>(null)
const wholeThing = ref<HTMLElement | null>(null)
const latestContent = ref<HTMLElement | null>(null)

// State
const demoStep = ref('setup')
const ageBand = ref('5-7')
const length = ref('6')
const currentStoryPrompt = ref('')
const isTypingDone = ref(false)
const demoAnswers = ref([])
const storyIndex = ref(0)

interface StoryPart {
  text: string;
  options: Record<string, StoryPart>;
  title?: string;
}

interface StoryMetadata {
  prompt: string;
}

interface StoryConfig {
  metadata: StoryMetadata;
  content: Record<string, StoryPart>;
}

const storyConfigs = ref<StoryConfig[]>([])
const currentStoryConfig = ref<StoryConfig | null>(null)
let currentNode = ref<StoryPart | null>(null)

const currentStoryTitle = computed(() => {
  if (!currentStoryConfig.value?.content[ageBand.value]) return ''
  return currentStoryConfig.value.content[ageBand.value].title || ''
})

// Computed
const currentStory = computed(() => {
  return currentNode.value
})

// Load all story configurations
const loadStoryConfigs = async () => {
  try {
    const storyFiles = [
      '/stories/space.json',
      '/stories/superherocat.json',
      '/stories/rhymes.json',
    ]

    const configs = await Promise.all(
        storyFiles.map(async (file) => {
          const response = await fetch(file)
          if (!response.ok) throw new Error(`Failed to load story: ${file}`)
          const content = await response.json()
          return content as StoryConfig
        })
    )

    storyConfigs.value = configs
    currentStoryConfig.value = configs[0]

    // Start typing the selected story's prompt
    startTyping(currentStoryConfig.value.metadata.prompt)
  } catch (error) {
    console.error('Failed to load stories:', error)
  }
}

const startTyping = (targetText: string) => {
  let currentIndex = 0
  isTypingDone.value = false
  currentStoryPrompt.value = ''

  const interval = setInterval(() => {
    if (currentIndex <= targetText.length) {
      currentStoryPrompt.value = targetText.slice(0, currentIndex)
      currentIndex++
    } else {
      isTypingDone.value = true
      clearInterval(interval)
    }
  }, 50)
}

const startDemo = async () => {
  demoStep.value = 'story'
  currentNode.value = currentStoryConfig.value?.content[ageBand.value]
  await nextTick()
  scrollToElement(wholeThing.value!)
}

const selectOption = async (option: string) => {
  if (!currentNode.value) return

  demoAnswers.value.push({
    question: Object.keys(currentNode.value.options),
    answer: option,
    text: currentNode.value.text
  })

  currentNode.value = currentNode.value.options[option]

  await nextTick()
  scrollToElement(latestContent.value!)
}

const resetDemo = async () => {
  // Pick a new random story
  storyIndex.value = (storyIndex.value + 1) % storyConfigs.value.length
  currentStoryConfig.value = storyConfigs.value[storyIndex.value]

  demoStep.value = 'setup'
  demoAnswers.value = []
  currentNode.value = null
  startTyping(currentStoryConfig.value.metadata.prompt)
  await nextTick()
  scrollToElement(storyForm.value!)
}

const redirectToPurchase = () => {
  router.push('/purchase')
}

// Helper functions
const ageEmoji = (age: string): string => {
  switch (age) {
    case '3-4':
      return '👶'
    case '5-7':
      return '🧒'
    case '8+':
      return '🧑'
    default:
      return '👤'
  }
}

const lengthEmoji = (value: string): string => {
  switch (value) {
    case '4':
      return '🐁'
    case '6':
      return '🐈'
    case '8':
      return '🐘'
    default:
      return '📏'
  }
}

// Lifecycle hooks
onMounted(async () => {
  await loadStoryConfigs()
  scrollToElement(storyForm.value!)
})
</script>

<style scoped>
* {
  overflow-anchor: none !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fadeIn 0.5s ease-out forwards;
}

.typing-container {
  display: inline-block;
  position: relative;
  width: 100%;
  line-height: 1.5;
}

.typing-cursor {
  position: relative;
  display: inline-block;
  width: 2px;
  height: 1.2em;
  background-color: rgb(203 213 225);
  margin-left: 1px;
  animation: blink 1s step-end infinite;
  vertical-align: text-bottom;
  transform: translateY(-1px);
}

@keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
  word-break: break-word;
}
</style>