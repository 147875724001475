import { watch } from 'vue'

class StorageManager {
    private static instance: StorageManager
    private consentGranted = false
    private originalStorageMethods = {
        localStorage: {
            setItem: localStorage.setItem.bind(localStorage),
            getItem: localStorage.getItem.bind(localStorage),
            clear: localStorage.clear.bind(localStorage)
        },
        sessionStorage: {
            setItem: sessionStorage.setItem.bind(sessionStorage),
            getItem: sessionStorage.getItem.bind(sessionStorage),
            clear: sessionStorage.clear.bind(sessionStorage)
        },
        originalIndexedDB: window.indexedDB,
    }

    private constructor() {
        // Block localStorage
        this.overrideStorage(localStorage)

        // Block sessionStorage
        this.overrideStorage(sessionStorage)

        // Block IndexedDB
        this.overrideIndexedDB()
    }

    private overrideStorage(storage: Storage) {
        storage.setItem = (...args: Parameters<typeof storage.setItem>) => {
            if (this.consentGranted) {
                this.originalStorageMethods[storage === localStorage ? 'localStorage' : 'sessionStorage'].setItem.apply(storage, args)
            }
        }

        storage.getItem = (...args: Parameters<typeof storage.getItem>) => {
            if (this.consentGranted) {
                return this.originalStorageMethods[storage === localStorage ? 'localStorage' : 'sessionStorage'].getItem.apply(storage, args)
            }
            return null
        }
    }

    private overrideIndexedDB() {
        Object.defineProperty(window, 'indexedDB', {
            get: () => {
                if (this.consentGranted) {
                    return this.originalStorageMethods.originalIndexedDB
                }
                return null
            }
        })
    }

    static getInstance() {
        if (!StorageManager.instance) {
            StorageManager.instance = new StorageManager()
        }
        return StorageManager.instance
    }

    private clearCookies() {
        // Get all cookies and split into array
        const cookies = document.cookie.split(';')

        // For each cookie, set an expired date to remove it
        for (const cookie of cookies) {
            const name = cookie.split('=')[0].trim()
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.${window.location.hostname}`
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`

            // Also try removing it from the base domain
            const domain = window.location.hostname.split('.').slice(1).join('.')
            if (domain) {
                document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.${domain}`
            }
        }
    }

    setConsent(granted: boolean) {
        this.consentGranted = granted
        if (!granted) {
            // Clear all storage types
            this.originalStorageMethods.localStorage.clear()
            this.originalStorageMethods.sessionStorage.clear()
            // Delete all IndexedDB databases
            if (this.originalStorageMethods.originalIndexedDB) {
                this.originalStorageMethods.originalIndexedDB.databases().then(dbs => {
                    dbs.forEach(db => {
                        this.originalStorageMethods.originalIndexedDB.deleteDatabase(db.name)
                    })
                })
            }
            this.clearCookies()
        }
    }
}

export const storageManager = StorageManager.getInstance()