import {defineStore} from 'pinia'
import {computed, ref, type Ref} from 'vue'
import * as types from '@/types/session'
import {ApiError, apiRequest} from '@/utils/api'
import {useRouter} from "vue-router";
import type {CreatePaymentResponse} from "@/types/session";

export const useSessionStore = defineStore('session', () => {
    const currentSession = ref<types.Session | null>(null)
    const currentUser = ref<types.User | null>(null)
    const router = useRouter()

    const loading = ref(false)
    const loadingNewAnswer = ref(false)
    const error = ref<ApiError | null>(null)

    const isSessionActive = computed(() => currentSession.value !== null)
    let currentFetchUserPromise: Promise<void> | null = null

    function clearStore() {
        currentSession.value = null
        currentUser.value = null
        error.value = null
        loading.value = false
        loadingNewAnswer.value = false
        currentFetchUserPromise = null
    }

    async function setMarketingPreferences(enabled: boolean) {
        const result = await apiRequest<types.User>(
            `/user/marketing`,
            {
                method: 'POST',
                body: JSON.stringify({
                    email_marketing_enabled: enabled
                }),
            },
            {loading, error}
        )
        handleError(error)
        if (result) {
            currentUser.value = result
        }
    }

    async function fetchUser() {
        // If there's already a fetch in progress, return that promise
        if (currentFetchUserPromise) {
            return currentFetchUserPromise
        }

        // If we already have a user, no need to fetch
        if (currentUser.value) {
            return Promise.resolve()
        }

        // Create and store the new fetch promise
        currentFetchUserPromise = (async () => {
            try {
                const result = await apiRequest<types.User>(
                    `/user`,
                    {method: 'GET'},
                    {loading, error}
                )
                handleError(error)
                if (result) {
                    currentUser.value = result
                }
            } finally {
                // Clear the promise when done, regardless of success/failure
                currentFetchUserPromise = null
            }
        })()

        // Return the promise
        return currentFetchUserPromise
    }

    async function listSessions(after: string): Promise<types.SessionSummary[] | null> {
        const result = await apiRequest<types.SessionSummary[]>(
            `/session/list`,
            {
                method: 'POST',
                body: JSON.stringify({after: after}),
            },
            {loading, error}
        )

        handleError(error)
        return result
    }

    async function fetchSession(sessionId: string) {
        if (currentSession.value?.id === sessionId) {
            return
        }
        currentSession.value = null

        const result = await apiRequest<types.Session>(
            `/session?session_id=${sessionId}`,
            {method: 'GET'},
            {loading, error}
        )
        handleError(error)
        if (result) {
            currentSession.value = result
        }
    }

    async function createNewSession(sessionParams: types.SessionParams): Promise<types.NewSessionResponse | null> {
        const result = await apiRequest<types.NewSessionResponse>(
            `/session/new`,
            {
                method: 'POST',
                body: JSON.stringify({session_params: sessionParams}),
            },
            {loading, error}
        )
        handleError(error)
        if (result) {
            currentUser.value = result.user
        }
        if (result?.is_suitable_for_children) {
            currentSession.value = result.session
        }
        return result
    }

    async function answerQuestion(chosenOption: string) {
        if (!currentSession.value?.id) return

        loadingNewAnswer.value = true
        const result = await apiRequest<types.SessionAndUserResponse>(
            `/session/answer`,
            {
                method: 'POST',
                body: JSON.stringify({
                    session_id: currentSession.value.id,
                    chosen_option: chosenOption,
                }),
            },
            {loading, error}
        )
        handleError(error)
        if (result) {
            currentSession.value = result.session
            currentUser.value = result.user
        }

        loadingNewAnswer.value = false
    }

    async function submitFeedback(feedback: types.SessionFeedback) {
        if (!currentSession.value?.id) return
        if (currentSession.value?.session_feedback.value == feedback.value) {
            return
        }

        const result = await apiRequest<types.Session>(
            `/session/feedback`,
            {
                method: 'POST',
                body: JSON.stringify({
                    session_id: currentSession.value.id,
                    feedback: feedback,
                }),
            },
            {loading, error}
        )
        handleError(error)
        if (result) {
            currentSession.value = result
        }
    }

    async function toggleVisibility() {
        if (!currentSession.value?.id) return

        const result = await apiRequest<types.Session>(
            `/session/visibility`,
            {
                method: 'POST',
                body: JSON.stringify({
                    session_id: currentSession.value.id,
                    is_public: !currentSession.value.is_public,
                }),
            },
            {loading, error}
        )
        handleError(error)
        if (result) {
            currentSession.value = result
        }
    }

    async function fulfillPayment(sessionId: string) {
        const result = await apiRequest<types.FulfillPaymentResponse>(
            `/stripe/payment/fulfill`,
            {
                method: 'POST',
                body: JSON.stringify({
                    session_id: sessionId,
                    user_id: currentUser.value?.id,
                }),
            },
            { loading, error }
        )
        handleError(error)
        if (result) {
            currentUser.value = result.user
        }
        return result
    }

    async function createPayment(acceptTerms: boolean, acceptWithdrawal: boolean) : Promise<CreatePaymentResponse | null> {
        const result = await apiRequest<types.CreatePaymentResponse>(
            `/stripe/payment/create`,
            {
                method: 'POST',
                body: JSON.stringify({
                    'terms_and_conditions_and_privacy_policy_agreement': acceptTerms,
                    'provide_service_despite_withdrawal_period': acceptWithdrawal,
                })
            },
            { loading, error }
        )
        handleError(error)
        if (result) {
            currentUser.value = result.user
        }
        return result
    }

    async function healthcheck(){
       await apiRequest(
            `/healthcheck`,
            {
                method: 'GET',
            },
            { loading, error }
        )
        handleError(error)
    }


    function handleError(err: Ref<ApiError | null>) {
        if (err.value?.statusCode === 401) {
            router.push('/login')
        }
        if (err.value?.statusCode === 402) {
            clearStore()
            router.push('/purchase')
        }
        if (err.value?.statusCode === 451) {
            router.push('/restricted')
        }
    }

    return {
        currentSession,
        loading,
        loadingNewAnswer,
        error,
        isSessionActive,
        currentUser,
        fetchUser,
        fetchSession,
        createNewSession,
        answerQuestion,
        submitFeedback,
        toggleVisibility,
        listSessions,
        clearStore,
        fulfillPayment,
        createPayment,
        healthcheck,
        setMarketingPreferences,
    }
})