<!-- src/components/FinishAuth.vue -->
<template>
  <div class="p-6 md:p-12">
    <div class="max-w-md mx-auto">
      <div class="p-6 space-y-6">
        <div class="text-center">
          <div v-if="isLoading" class="flex flex-col items-center space-y-4">
            <div class="flex items-center space-x-2">
              <span class="text-slate-300">Processing</span>
              <LoadingDots/>
            </div>
          </div>
          <template v-else>
            <CircleCheck v-if="success" class="h-12 w-12 mx-auto text-emerald-400 mb-4"/>
            <AlertTriangle v-else class="h-12 w-12 mx-auto text-yellow-400 mb-4"/>

            <h2 class="text-2xl font-display font-bold text-slate-100 mb-2">
              {{ title }}
            </h2>
            <p class="text-slate-300 mb-6">
              {{ message }}
            </p>

            <div v-if="error"
                 class="p-4 bg-red-600/10 border border-red-500/20 rounded-lg mb-6">
              <div class="flex items-start space-x-3">
                <AlertTriangle class="h-5 w-5 text-red-400 flex-shrink-0 mt-0.5"/>
                <p class="text-red-400 text-sm">{{ error }}</p>
              </div>
            </div>

            <button
                v-if="!isRedirecting"
                @click="redirectToApp"
                class="w-full p-4 bg-blue-600 hover:bg-blue-500
                     text-white rounded-lg font-medium
                     transition-all duration-200
                     flex items-center justify-center space-x-3
                     group"
            >
              <LogIn v-if="!user" class="h-5 w-5 group-hover:-translate-x-0.5 transition-transform"/>
              <ArrowRight v-else class="h-5 w-5 group-hover:translate-x-1 transition-transform"/>
              <span>{{ user ? 'Continue to App' : 'Sign In' }}</span>
            </button>
            <div v-else class="flex items-center justify-center space-x-2">
              <span class="text-slate-300">Redirecting</span>
              <LoadingDots/>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {onMounted, ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {applyActionCode, getAuth, verifyPasswordResetCode} from 'firebase/auth'
import {getCurrentUser, useCurrentUser} from 'vuefire'
import {AlertTriangle, ArrowRight, CircleCheck, LogIn} from 'lucide-vue-next'
import LoadingDots from './LoadingDots.vue'
import {getFirebaseErrorMessage} from '@/utils/firebase'

const router = useRouter()
const route = useRoute()
const success = ref(false)
const error = ref('')
const title = ref('Processing...')
const message = ref('Please wait while we verify your request.')
const isLoading = ref(true)
const isRedirecting = ref(false)
const user = useCurrentUser()

onMounted(async () => {
  const mode = route.query.mode as string
  const actionCode = route.query.oobCode as string

  if (!actionCode) {
    isLoading.value = false
    error.value = 'Invalid verification link.'
    return
  }

  const auth = getAuth()
  const user = await getCurrentUser()

  try {
    switch (mode) {
      case 'resetPassword':
        // Verify the code first
        await verifyPasswordResetCode(auth, actionCode)
        isRedirecting.value = true
        // Redirect to reset password form with the action code
        router.push({
          path: '/reset-password',
          query: {oobCode: actionCode}
        })
        break

      case 'verifyEmail':
        if (user?.emailVerified) {
          success.value = true
          title.value = 'Email Already Verified!'
          message.value = 'Your email has been already verified. You can continue using the app.'
          break
        } else {
          await applyActionCode(auth, actionCode)
          const user = await getCurrentUser()
          // Refresh the user token to update emailVerified status
          if (user) {
            await user?.reload()
            await user?.getIdToken(true)
            message.value = 'Your email has been successfully verified. You can now continue using the app.'
          } else {
            message.value = 'Your email has been successfully verified. Please sign in to continue.'
          }

          success.value = true
          title.value = 'Email Verified!'
          break
        }

      default:
        throw new Error('Invalid action type.')
    }
  } catch (e) {
    success.value = false
    title.value = 'Verification Failed'
    error.value = getFirebaseErrorMessage(e)
    console.error('Verification error:', e)
  } finally {
    isLoading.value = false
  }
})

const redirectToApp = () => {
  isRedirecting.value = true
  router.push("/login")
}
</script>