<!-- src/components/ResetPassword.vue -->
<template>
  <div class="p-6 md:p-12">
    <div class="max-w-md mx-auto">
      <div class="bg-slate-800/50 backdrop-blur border border-slate-700/50 rounded-xl overflow-hidden">
        <div class="p-6 space-y-6">
          <!-- Success State -->
          <div v-if="success" class="text-center">
            <CircleCheck class="h-12 w-12 mx-auto text-emerald-400 mb-4"/>
            <h2 class="text-2xl font-display font-bold text-slate-100 mb-4">
              Password Reset Successful
            </h2>
            <p class="text-slate-300 mb-6">
              Your password has been successfully reset. You can now sign in with your new password.
            </p>
            <button
                @click="redirectToLogin"
                class="w-full p-4 bg-blue-600 hover:bg-blue-500
                   text-white rounded-lg font-medium
                   transition-all duration-200
                   flex items-center justify-center space-x-3
                   group"
            >
              <LogIn class="h-5 w-5 group-hover:-translate-x-0.5 transition-transform"/>
              <span>Sign In</span>
            </button>
          </div>

          <!-- Reset Password Form -->
          <div v-else>
            <h2 class="text-2xl font-display font-bold text-slate-100 mb-6">
              Set New Password
            </h2>

            <form @submit.prevent="handleSubmit" class="space-y-4">
              <div>
                <label for="reset-password-new" class="block text-sm font-medium text-slate-300 mb-1">
                  New Password
                </label>
                <input
                    id="reset-password-new"
                    v-model="password"
                    type="password"
                    required
                    minlength="6"
                    class="w-full px-4 py-2 bg-slate-800/50 border border-slate-600/50 rounded-lg
                         text-slate-300 placeholder-slate-500
                         focus:border-blue-500/50 focus:ring-2 focus:ring-blue-500/20"
                    placeholder="Enter your new password"
                />
              </div>

              <div>
                <label for="reset-password-confirm" class="block text-sm font-medium text-slate-300 mb-1">
                  Confirm New Password
                </label>
                <input
                    id="reset-password-confirm"
                    v-model="confirmPassword"
                    type="password"
                    required
                    minlength="6"
                    class="w-full px-4 py-2 bg-slate-800/50 border border-slate-600/50 rounded-lg
                         text-slate-300 placeholder-slate-500
                         focus:border-blue-500/50 focus:ring-2 focus:ring-blue-500/20"
                    placeholder="Confirm your new password"
                />
              </div>

              <div v-if="error"
                   class="p-4 bg-red-600/10 border border-red-500/20 rounded-lg">
                <div class="flex items-start space-x-3">
                  <AlertTriangle class="h-5 w-5 text-red-400 flex-shrink-0 mt-0.5"/>
                  <p class="text-red-400 text-sm">{{ error }}</p>
                </div>
              </div>

              <button
                  type="submit"
                  :disabled="isLoading"
                  class="w-full p-4 bg-blue-600 hover:bg-blue-500 disabled:bg-blue-600/50
                       text-white rounded-lg font-medium
                       transition-all duration-200
                       flex items-center justify-center space-x-3"
              >
                <div v-if="isLoading" class="flex items-center space-x-2">
                  <span>Resetting Password</span>
                  <LoadingDots />
                </div>
                <span v-else>Reset Password</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { getAuth, confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth'
import LoadingDots from './LoadingDots.vue'
import { AlertTriangle, CircleCheck, LogIn } from 'lucide-vue-next'
import { getFirebaseErrorMessage } from '@/utils/firebase'

const router = useRouter()
const route = useRoute()
const password = ref('')
const confirmPassword = ref('')
const error = ref('')
const isLoading = ref(false)
const success = ref(false)

// Verify the code is still valid when component mounts
onMounted(async () => {
  const actionCode = route.query.oobCode as string
  if (!actionCode) {
    error.value = 'Invalid password reset link'
    return
  }

  try {
    const auth = getAuth()
    await verifyPasswordResetCode(auth, actionCode)
  } catch (e) {
    error.value = getFirebaseErrorMessage(e)
  }
})

const handleSubmit = async () => {
  if (password.value !== confirmPassword.value) {
    error.value = "Passwords don't match"
    return
  }

  const actionCode = route.query.oobCode as string
  if (!actionCode) {
    error.value = 'Invalid password reset link'
    return
  }

  isLoading.value = true
  try {
    const auth = getAuth()
    await confirmPasswordReset(auth, actionCode, password.value)
    success.value = true
  } catch (e) {
    error.value = getFirebaseErrorMessage(e)
    console.error('Password reset error:', e)
  } finally {
    isLoading.value = false
  }
}

const redirectToLogin = () => {
  router.push('/login')
}
</script>