// src/utils/analytics.ts
import {getAnalytics, setConsent} from 'firebase/analytics'
import type {FirebaseApp} from 'firebase/app'
import type {App} from "vue";
import type {Router} from 'vue-router'
import * as Sentry from "@sentry/vue";
import {getConsentPreferences} from "@/utils/cookie";

let firebaseApp: FirebaseApp | null = null
let firebaseAnalytics = null

const defaultConsent = {
    ad_storage: 'denied',
    ad_personalization: 'denied',
    ad_user_data: 'denied',
    analytics_storage: 'denied',
    personalization_storage: 'denied',
    functionality_storage: 'denied',
    security_storage: 'granted'
}

export function initializeAnalytics(app: FirebaseApp): void {
    firebaseApp = app

    const preferences = getConsentPreferences()

    if (!preferences?.analytics) {
        setConsent(defaultConsent)
    }
    updateAnalyticsConsent(preferences?.analytics!)
}

export function updateAnalyticsConsent(enabled: boolean): void {
    // Initialize analytics if it wasn't already and consent is now granted
    if (enabled) {
        if (firebaseAnalytics === null) {
            firebaseAnalytics = getAnalytics(firebaseApp!)
        }
    }

    const consent = {
        ...defaultConsent,
        analytics_storage: enabled ? 'granted' : 'denied'
    }
    setConsent(consent)
}

interface SentryConfig {
    app: App
    router: Router
    environment: string
}

export function initializeSentry(config: SentryConfig): void {
    const preferences = getConsentPreferences()

    Sentry.init({
        app: config.app,
        dsn: "https://a37acfc4528521778e0865b1055b5410@o4508326273810432.ingest.us.sentry.io/4508326275186688",
        integrations: [
            Sentry.browserTracingIntegration({ router: config.router }),
            Sentry.replayIntegration(),
        ],
        environment: config.environment,

        // Performance Monitoring
        tracesSampleRate: 1.0,
        tracePropagationTargets: [
            "localhost",
            /^https:\/\/api\.kidstelltales\.com/,
            /^https:\/\/kidstelltales\.com/
        ],

        // Session Replay
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,

        enabled: preferences?.analytics,
    });
}

export function updateSentryConsent(enabled: boolean): void {
    const client = Sentry.getCurrentHub().getClient();
    if (client) {
        client.getOptions().enabled = enabled;
    }
}