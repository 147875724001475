
export type CookieType = 'essential' | 'analytics'
export type CookiePreferences = Record<CookieType, boolean>

export const COOKIE_CONSENT_KEY = 'cookie_consent'

export function setCookie(name: string, value: string, days: number): void {
    const expires = new Date()
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000)
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/;SameSite=Lax`
}

export function getCookie(name: string): string | null {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))
    return match ? match[2] : null
}

export function getConsentPreferences(): CookiePreferences {
    const consent = getCookie(COOKIE_CONSENT_KEY)
    if (!consent) return {
        essential: false,
        analytics: false,
    }

    try {
        const parsed = JSON.parse(consent)
        return {
            essential: true, // Always enabled
            analytics: Boolean(parsed.analytics),
        }
    } catch {
        return {
            essential: false,
            analytics: false,
        }
    }
}

export function saveConsentPreferences(preferences: CookiePreferences): void {
    setCookie(COOKIE_CONSENT_KEY, JSON.stringify(preferences), 365)
}