<template>
  <div v-if="subscriptionInfo.available && !subscriptionInfo.hasActiveSubscription"
       class="relative overflow-hidden rounded-xl backdrop-blur-sm my-6">
    <!-- Background with gradient and subtle pattern -->
    <div class="absolute inset-0 bg-gradient-to-r from-blue-600/10 via-indigo-600/10 to-purple-600/10">
      <div class="absolute inset-0 bg-slate-800/40"></div>
    </div>

    <!-- Content -->
    <div class="relative p-6">
      <div class="flex flex-col md:flex-row md:items-center md:justify-between gap-6">
        <!-- Left side: Message -->
        <div class="space-y-3">
          <div class="flex items-center gap-3">
            <div class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-blue-600/10 border border-blue-500/20">
              <Sparkles class="h-5 w-5 text-blue-400" />
            </div>
            <h3 class="text-lg font-display font-semibold bg-gradient-to-r from-blue-400 to-indigo-400 bg-clip-text text-transparent">
              Unlock More Stories
            </h3>
          </div>
          <p class="text-slate-300">
            {{ subscriptionInfo.statusMessage }}
          </p>
          <p class="text-sm text-slate-400">
            Questions? Visit our
            <router-link
                to="/help"
                class="text-blue-400 hover:text-blue-300 underline transition-colors duration-200"
            >
              Help page
            </router-link>
            for more information.
          </p>
        </div>

        <!-- Right side: CTA -->
        <div class="flex-shrink-0">
          <button
              @click="redirectToPurchase"
              class="w-full md:w-auto inline-flex items-center justify-center px-6 py-3
                   bg-blue-600 hover:bg-blue-500 text-white rounded-lg font-semibold
                   transition-all duration-200 group gap-2 shadow-lg hover:shadow-blue-500/25"
          >
            <Ticket class="h-5 w-5 group-hover:scale-110 transition-transform" />
            <span>{{subscriptionInfo.neverHadSubscription ? "Purchase now" : "Get More Stories"}}</span>
            <ArrowRight class="h-5 w-5 group-hover:translate-x-1 transition-transform" />
          </button>
        </div>
      </div>
    </div>

    <!-- Decorative elements -->
    <div class="absolute -top-12 -right-12 w-32 h-32 bg-blue-500/10 rounded-full blur-2xl"></div>
    <div class="absolute -bottom-8 -left-8 w-24 h-24 bg-indigo-500/10 rounded-full blur-2xl"></div>
  </div>
</template>

<script setup lang="ts">
import {useRoute, useRouter} from 'vue-router'
import { Sparkles, Ticket, ArrowRight } from 'lucide-vue-next'
import { useSubscription } from "@/composables/useSubscription"
import {onMounted} from "vue";
import {useMetaTags} from "@/components/useMetaTags";
import {useSessionStore} from "@/store/session";

const route = useRoute()
const router = useRouter()
const { subscriptionInfo } = useSubscription()
const sessionStore = useSessionStore()

const redirectToPurchase = () => {
  router.push('/purchase')
}

onMounted(async () => {
  if (!sessionStore.currentUser) {
    await sessionStore.fetchUser()
  }

  if(subscriptionInfo.value.available && subscriptionInfo.value.neverHadSubscription && route.name !== "Account") {
    router.push('/purchase')
  }
})

</script>