<template>
  <div>
    <LoadingSkeleton v-if="!sessionStore.currentUser && sessionStore.loading" text="Loading your stories"/>
    <div v-else>
      <!-- Header Section -->
      <div class="p-6 bg-gradient-to-b from-slate-800/50 to-transparent">
        <div class="max-w-2xl mx-auto md:text-center">
          <h1 class="text-3xl md:text-4xl font-display font-bold text-slate-100 md:mb-6">
            Your Stories
          </h1>

          <SubscriptionBanner/>

          <!-- New Story Button - Desktop only -->
          <button
              v-if="sessionStore.currentUser"
              :disabled="!subscriptionInfo.hasActiveSubscription"
              @click="startNewSession"
              class="hidden md:flex w-full btn-primary md:bg-blue-600 md:hover:bg-blue-500 group items-center justify-center space-x-3 text-lg"
          >
            <Sparkles class="h-5 w-5 group-hover:scale-110 transition-transform"/>
            <span class="font-semibold">Start a New Adventure</span>
            <ArrowRight class="h-5 w-5 group-hover:translate-x-1 transition-transform"/>
          </button>
        </div>
      </div>

      <!-- Main Content -->
      <div class="px-4 md:px-6 pb-6 md:py-6">
        <div v-if="sessionStore.currentUser">
          <!-- Empty State -->
          <div
              v-if="allSessions.length === 0"
              class="max-w-2xl mx-auto text-center py-4"
          >
            <button
                v-if="sessionStore.currentUser"
                @click="startNewSession"
                :disabled="!subscriptionInfo.hasActiveSubscription"
                class="flex md:hidden w-full btn-primary md:bg-blue-600 md:hover:bg-blue-500 group items-center justify-center space-x-3 mb-10 text-lg"
            >
              <Sparkles class="h-5 w-5 group-hover:scale-110 transition-transform"/>
              <span class="font-semibold">Start a New Adventure</span>
              <ArrowRight class="h-5 w-5 group-hover:translate-x-1 transition-transform"/>
            </button>
            <div class="flex justify-center mb-6">
              <Library class="h-16 w-16 text-slate-400"/>
            </div>
            <h2 class="text-xl font-semibold text-slate-200 mb-2">Your Library is Empty</h2>
            <p class="text-slate-400 mb-8">Start your first adventure and begin building your collection!</p>
          </div>

          <!-- Sessions List -->
          <div v-else class="max-w-3xl mx-auto space-y-8">
            <!-- Today's Group (always shown with button on mobile) -->
            <div class="space-y-4">
              <h2 class="text-lg font-medium text-slate-400 px-1">Today</h2>

              <!-- New Story Button - Mobile only, always under "Today" -->
              <button
                  class="md:hidden w-full btn-primary bg-blue-600/10 hover:bg-blue-600/20 group flex items-center justify-center space-x-3 py-3"
                  @click="startNewSession"
                  :disabled="!subscriptionInfo.hasActiveSubscription"
              >
                <Sparkles class="h-5 w-5 group-hover:scale-110 transition-transform"/>
                <span class="font-semibold">Start a New Adventure</span>
              </button>

              <!-- Today's Stories -->
              <div v-if="todayStories.length > 0" class="space-y-3">
                <div
                    v-for="session in todayStories"
                    :key="session.id"
                    class="group relative px-4 py-3 md:px-6 md:py-4 rounded-lg
                         transition-all duration-200
                         hover:bg-slate-800/30 active:bg-slate-800/50
                         sm:hover:bg-slate-800/30"
                    @click="loadSession(session.id)"
                >
                  <div class="flex flex-col sm:flex-row sm:items-center gap-3">
                    <div class="flex-grow flex items-center gap-3">
                      <!-- Mobile Status Icon -->
                      <div class="sm:hidden">
                        <component
                            :is="session.is_finished ? BookOpen : PencilLine"
                            class="h-5 w-5"
                            :class="session.is_finished ? 'text-slate-400' : 'text-blue-400'"
                        />
                      </div>
                      <div class="flex-grow">
                        <h3 class="text-lg font-semibold text-slate-100 group-hover:text-white transition-colors">
                          {{ session.title }}
                        </h3>
                        <div class="flex items-center space-x-2 text-sm text-slate-400">
                          <Clock class="h-4 w-4"/>
                          <span>{{ formatDate(session.updated) }}</span>
                        </div>
                      </div>
                      <!-- Mobile Chevron -->
                      <div class="sm:hidden flex items-center">
                        <ChevronRight class="h-5 w-5 text-slate-500 group-hover:text-slate-300 transition-colors"/>
                      </div>
                    </div>

                    <button
                        @click="loadSession(session.id)"
                        :class="session.is_finished ? 'btn-secondary' : 'btn-primary'"
                        class="hidden sm:flex w-full sm:w-32 items-center justify-center space-x-2 group/btn"
                    >
                      <component
                          :is="session.is_finished ? Book : PencilLine"
                          class="h-4 w-4 group-hover/btn:scale-110 transition-transform"
                      />
                      <span>{{ session.is_finished ? 'Read' : 'Continue' }}</span>
                    </button>
                  </div>

                  <!-- Mobile touch ripple effect -->
                  <div class="absolute inset-0 sm:hidden">
                    <div class="absolute inset-0 rounded-lg transition-colors duration-200
                              group-active:bg-slate-400/10"></div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Other Time Groups -->
            <div
                v-for="group in otherGroups"
                :key="group.label"
                class="space-y-4"
            >
              <h2 class="text-lg font-medium text-slate-400 px-1">{{ group.label }}</h2>

              <div class="space-y-3">
                <div
                    v-for="session in group.sessions"
                    :key="session.id"
                    class="group relative px-4 py-3 md:px-6 md:py-4 rounded-lg
                         transition-all duration-200
                         hover:bg-slate-800/30 active:bg-slate-800/50
                         sm:hover:bg-slate-800/30"
                    @click="loadSession(session.id)"
                >
                  <div class="flex flex-col sm:flex-row sm:items-center gap-3">
                    <div class="flex-grow flex items-center gap-3">
                      <!-- Mobile Status Icon -->
                      <div class="sm:hidden">
                        <component
                            :is="session.is_finished ? BookOpen : PencilLine"
                            class="h-5 w-5"
                            :class="session.is_finished ? 'text-slate-400' : 'text-blue-400'"
                        />
                      </div>
                      <div class="flex-grow">
                        <h3 class="text-lg font-semibold text-slate-100 group-hover:text-white transition-colors">
                          {{ session.title }}
                        </h3>
                        <div class="flex items-center space-x-2 text-sm text-slate-400">
                          <Clock class="h-4 w-4"/>
                          <span>{{ formatDate(session.updated) }}</span>
                        </div>
                      </div>
                      <!-- Mobile Chevron -->
                      <div class="sm:hidden flex items-center">
                        <ChevronRight class="h-5 w-5 text-slate-500 group-hover:text-slate-300 transition-colors"/>
                      </div>
                    </div>

                    <button
                        @click="loadSession(session.id)"
                        :class="session.is_finished ? 'btn-secondary' : 'btn-primary'"
                        class="hidden sm:flex w-full sm:w-32 items-center justify-center space-x-2 group/btn"
                    >
                      <component
                          :is="session.is_finished ? Book : PencilLine"
                          class="h-4 w-4 group-hover/btn:scale-110 transition-transform"
                      />
                      <span>{{ session.is_finished ? 'Read' : 'Continue' }}</span>
                    </button>
                  </div>

                  <!-- Mobile touch ripple effect -->
                  <div class="absolute inset-0 sm:hidden">
                    <div class="absolute inset-0 rounded-lg transition-colors duration-200
                              group-active:bg-slate-400/10"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Load More Button -->
          <div v-if="showLoadMore" class="mt-8 text-center">
            <button
                @click="loadMoreSessions"
                class="btn-secondary px-6 group flex items-center space-x-2 mx-auto"
                :disabled="sessionStore.loading"
            >
            <span v-if="!sessionStore.loading" class="flex items-center justify-center space-x-2">
              <span>Show more stories</span>
              <BookOpen class="h-4 w-4 group-hover:scale-110 transition-transform"/>
            </span>
              <span v-else class="flex items-center justify-center space-x-2">
              <span>Loading</span>
              <LoadingDots/>
            </span>
            </button>
          </div>
        </div>

      </div>
    </div>
    <ErrorBox/>
  </div>
</template>

<script setup lang="ts">
import {computed, onMounted, ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import type {SessionSummary} from '@/types/session'
import {useSessionStore} from "@/store/session"
import LoadingDots from "@/components/LoadingDots.vue"
import ErrorBox from "@/components/ErrorBox.vue"
import {ArrowRight, Book, BookOpen, ChevronRight, Clock, Library, PencilLine, Sparkles} from 'lucide-vue-next'
import {useMetaTags} from "@/components/useMetaTags";
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import {useSubscription} from "@/composables/useSubscription";
import SubscriptionBanner from "@/components/SubscriptionBanner.vue";

const router = useRouter()
const route = useRoute()
const sessionStore = useSessionStore()
const showLoadMore = ref(false)
const allSessions = ref<SessionSummary[]>([])
const {subscriptionInfo} = useSubscription()


const loadSession = (sessionId: string): void => {
  router.push(`/sessions/${sessionId}`)
}

const startNewSession = () => {
  router.push('/sessions/new')
}

const formatDate = (dateString: string): string => {
  const date = new Date(dateString)
  return new Intl.DateTimeFormat(navigator.language, {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }).format(date)
}

const todayStories = computed(() => {
  const now = new Date()
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

  return allSessions.value.filter(session => {
    const sessionDate = new Date(session.updated)
    return sessionDate >= today
  })
})

const otherGroups = computed(() => {
  const now = new Date()
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)
  const thisWeekStart = new Date(today)
  thisWeekStart.setDate(thisWeekStart.getDate() - thisWeekStart.getDay())
  const thisMonthStart = new Date(now.getFullYear(), now.getMonth(), 1)

  const groups = [
    {label: 'Yesterday', sessions: []},
    {label: 'This Week', sessions: []},
    {label: 'This Month', sessions: []},
    {label: 'Older Stories', sessions: []}
  ]

  allSessions.value.forEach(session => {
    const sessionDate = new Date(session.updated)
    if (sessionDate >= today) {
      return // Skip today's stories as they're handled separately
    } else if (sessionDate >= yesterday) {
      groups[0].sessions.push(session)
    } else if (sessionDate >= thisWeekStart) {
      groups[1].sessions.push(session)
    } else if (sessionDate >= thisMonthStart) {
      groups[2].sessions.push(session)
    } else {
      groups[3].sessions.push(session)
    }
  })

  return groups.filter(group => group.sessions.length > 0)
})

const loadMoreSessions = async () => {
  const lastSession = allSessions.value[allSessions.value.length - 1]
  const after = lastSession ? new Date(lastSession.updated).toISOString() : new Date(0).toISOString()

  const newSessions = await sessionStore.listSessions(after)

  if (newSessions) {
    allSessions.value = [...allSessions.value, ...newSessions]
    showLoadMore.value = newSessions.length === 10
  }
}

onMounted(async () => {
  useMetaTags({
    title: 'Your Stories',
    path: route.path,
  })

  if (!sessionStore.currentUser) {
    await sessionStore.fetchUser()
  }

  if (sessionStore.currentUser) {
    allSessions.value = sessionStore.currentUser.recent_sessions || []
    showLoadMore.value = allSessions.value.length === 25
  }
})
</script>