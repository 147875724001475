<template>
  <div class="min-h-screen">
    <!-- Hero Section -->
    <div class="relative overflow-hidden px-6 pt-12 pb-16 lg:px-8">
      <div class="absolute inset-0">
        <div class="absolute w-72 h-72 bg-blue-600/10 rounded-full blur-3xl -top-32 -right-32"></div>
        <div class="absolute w-72 h-72 bg-indigo-600/10 rounded-full blur-3xl -bottom-32 -left-32"></div>
      </div>

      <div class="relative max-w-3xl mx-auto text-center">
        <div class="inline-block animate-bounce-sparkle mb-4">
          <span class="text-5xl md:text-6xl">✨</span>
        </div>

        <h1 class="text-4xl md:text-6xl font-display font-bold bg-gradient-to-r from-blue-400 to-indigo-400 text-transparent bg-clip-text">
          Create Magical Stories with Your Children
        </h1>
        <p class="mt-6 text-lg md:text-xl text-slate-300">
          Transform storytime into an interactive adventure where children shape the narrative through their choices
        </p>

        <!-- Demo Trigger - More prominent CTA -->
        <button
            v-if="!showDemo"
            @click="showDemo = true"
            class="mt-12 inline-flex items-center px-8 py-4 text-lg font-semibold
                 bg-gradient-to-r from-blue-600 to-indigo-600
                 hover:from-blue-500 hover:to-indigo-500
                 text-white rounded-full shadow-lg
                 hover:shadow-blue-500/25 hover:-translate-y-0.5
                 transform transition-all duration-200"
        >
          <Sparkles class="h-5 w-5 mr-2 animate-pulse" />
          <span>Try it yourself!</span>
          <ChevronDown class="ml-2 h-5 w-5" />
        </button>
      </div>
    </div>

    <!-- Demo Section -->
    <Transition
        enter-active-class="transition-all duration-500 ease-out"
        enter-from-class="opacity-0 -translate-y-4"
        enter-to-class="opacity-100 translate-y-0"
    >
      <div v-if="showDemo" class="md:px-2">
        <div class="max-w-6xl mx-auto">
          <!-- Demo Component -->
          <Demo class="max-w-[105vw] mx-auto" />
        </div>
      </div>
    </Transition>

    <!-- Feature Grid -->
    <div class="px-6 py-16">
      <div class="max-w-3xl mx-auto">
        <div class="grid md:grid-cols-2 gap-6">
          <div
              v-for="(feature, index) in features"
              :key="index"
              class="p-6 bg-slate-800/30 rounded-xl border border-slate-700/50 hover:border-blue-500/20 transition-all duration-300 hover:-translate-y-1 group"
          >
            <div class="flex items-start space-x-4">
              <div class="flex-shrink-0">
                <div class="p-3 rounded-lg bg-blue-600/10 text-blue-400 group-hover:scale-110 transition-transform">
                  <component :is="feature.icon" class="h-6 w-6"/>
                </div>
              </div>
              <div>
                <h3 class="text-xl font-display font-semibold text-slate-100 mb-2">
                  {{ feature.title }}
                </h3>
                <p class="text-slate-400">
                  {{ feature.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Testimonials -->
    <div class="px-6 py-16 bg-slate-800/30 border-y border-slate-700/50">
      <div class="max-w-3xl mx-auto">
        <h2 class="text-2xl md:text-3xl font-display font-bold text-center text-slate-100 mb-12">
          Parents Love Our Stories
        </h2>

        <div class="grid md:grid-cols-2 gap-6">
          <div
              v-for="(testimonial, index) in testimonials"
              :key="index"
              class="p-6 bg-slate-800/50 rounded-xl border border-slate-700/50"
          >
            <div class="flex items-start space-x-4">
              <div class="flex-shrink-0">
                <div class="w-12 h-12 rounded-full bg-gradient-to-r from-blue-600/20 to-indigo-600/20 flex items-center justify-center">
                  <User class="h-6 w-6 text-blue-400"/>
                </div>
              </div>
              <div>
                <div class="text-yellow-400 mb-2">★★★★★</div>
                <p class="text-slate-300 mb-2">{{ testimonial.text }}</p>
                <p class="text-sm text-slate-400">{{ testimonial.author }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Final CTA -->
    <div class="px-6 py-16">
      <div class="max-w-2xl mx-auto text-center space-y-6">
        <h2 class="text-2xl md:text-3xl font-display font-bold text-slate-100">
          Ready to Create Your Own Stories?
        </h2>
        <button
            @click="redirectToPurchase"
            class="group inline-flex items-center justify-center px-8 py-4 bg-blue-600 hover:bg-blue-500 text-white rounded-lg text-lg font-semibold transition-all duration-200"
        >
          <Sparkles class="h-5 w-5 mr-2 group-hover:scale-110 transition-transform"/>
          <span>Start now for just $7.99</span>
          <ArrowRight class="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {onMounted, ref} from 'vue'
import {
  Sparkles,
  Star,
  Book,
  Brain,
  User,
  ChevronDown,
  ArrowRight
} from 'lucide-vue-next'
import {useRoute, useRouter} from 'vue-router'
import Demo from '@/components/Demo.vue'
import {useMetaTags} from "@/components/useMetaTags";

const router = useRouter()
const route = useRoute()
const showDemo = ref(false)

const redirectToPurchase = () => {
  router.push('/purchase')
}

onMounted(async () => {
  useMetaTags({
    title: '',
    path: route.path,
  })
})

const features = [
  {
    icon: Book,
    title: "Interactive Choices",
    description: "Children make meaningful decisions that shape their unique adventure"
  },
  {
    icon: Star,
    title: "Age-Appropriate",
    description: "Stories perfectly tailored for ages 3 and above, supporting their development"
  },
  {
    icon: Brain,
    title: "AI-Powered Magic",
    description: "Advanced AI ensures each story is unique and engaging"
  },
  {
    icon: Sparkles,
    title: "Educational Value",
    description: "Stories that entertain while supporting learning and development"
  }
]

const testimonials = [
  {
    text: "My daughter can't wait for our daily story time now. The interactive choices make her feel like she's part of the adventure!",
    author: "Sarah M., Mom of 6-year-old"
  },
  {
    text: "Finally found something that keeps both my 4 and 8 year olds engaged. The stories are magical and educational!",
    author: "Michael R., Father of two"
  }
]
</script>

<style scoped>
.animate-bounce-sparkle {
  animation: bounce-sparkle 3s infinite;
}

@keyframes bounce-sparkle {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-25%);
  }
}
</style>