<template>
  <div class="p-6 md:p-12">
    <div class="max-w-md mx-auto">
        <div class="p-6 space-y-6">
          <!-- Loading State -->
          <div v-if="sessionStore.loading" class="text-center space-y-4">
            <div class="flex items-center justify-center space-x-2">
              <span class="text-slate-300">Processing your payment</span>
              <LoadingDots />
            </div>
          </div>

          <!-- Success State -->
          <div v-else-if="!sessionStore.error" class="text-center">
            <CircleCheck class="h-12 w-12 mx-auto text-emerald-400 mb-4"/>
            <h2 class="text-2xl font-display font-bold text-slate-100 mb-4">
              Payment Successful!
            </h2>
            <button
                @click="redirectToHistory"
                class="w-full px-6 py-4 bg-blue-600 hover:bg-blue-500
                    text-white rounded-lg text-lg font-semibold
                    transition-all duration-200
                    flex items-center justify-center space-x-3
                    group"
            >
              <BookOpen class="h-5 w-5 group-hover:scale-110 transition-transform"/>
              <span>Start Creating Stories</span>
            </button>
          </div>

          <!-- Error State -->
          <div v-else-if="sessionStore.error" class="text-center">
            <AlertTriangle class="h-12 w-12 mx-auto text-yellow-400 mb-4"/>
            <h2 class="text-2xl font-display font-bold text-slate-100 mb-4">
              Payment Verification Failed
            </h2>
            <p class="text-slate-300 mb-6">
              We couldn't verify your payment at this time. If you believe this is an error,
              please contact our support team.
            </p>
            <div class="space-y-3">
              <button
                  @click="retryVerification"
                  class="w-full px-6 py-4 bg-blue-600 hover:bg-blue-500
                      text-white rounded-lg text-lg font-semibold
                      transition-all duration-200
                      flex items-center justify-center space-x-3
                      group"
              >
                <RefreshCw class="h-5 w-5 group-hover:rotate-180 transition-transform"/>
                <span>Try Again</span>
              </button>
              <button
                  @click="redirectToSupport"
                  class="w-full px-6 py-4 bg-slate-700 hover:bg-slate-600
                      text-slate-300 hover:text-white rounded-lg text-lg font-semibold
                      transition-all duration-200
                      flex items-center justify-center space-x-3
                      group"
              >
                <HelpCircle class="h-5 w-5 group-hover:scale-110 transition-transform"/>
                <span>Contact Support</span>
              </button>
            </div>
          </div>

          <!-- Session ID Missing State -->
          <div v-else-if="!sessionId" class="text-center">
            <AlertTriangle class="h-12 w-12 mx-auto text-yellow-400 mb-4"/>
            <h2 class="text-2xl font-display font-bold text-slate-100 mb-4">
              Invalid Payment Session
            </h2>
            <p class="text-slate-300 mb-6">
              We couldn't find the payment session. Please try making the purchase again or contact support if you believe this is an error.
            </p>
            <div class="space-y-3">
              <button
                  @click="redirectToPurchase"
                  class="w-full px-6 py-4 bg-blue-600 hover:bg-blue-500
                      text-white rounded-lg text-lg font-semibold
                      transition-all duration-200
                      flex items-center justify-center space-x-3
                      group"
              >
                <CreditCard class="h-5 w-5 group-hover:scale-110 transition-transform"/>
                <span>Try Purchasing Again</span>
              </button>
              <button
                  @click="redirectToSupport"
                  class="w-full px-6 py-4 bg-slate-700 hover:bg-slate-600
                      text-slate-300 hover:text-white rounded-lg text-lg font-semibold
                      transition-all duration-200
                      flex items-center justify-center space-x-3
                      group"
              >
                <HelpCircle class="h-5 w-5 group-hover:scale-110 transition-transform"/>
                <span>Contact Support</span>
              </button>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {
  BookOpen,
  CircleCheck,
  AlertTriangle,
  RefreshCw,
  HelpCircle,
  CreditCard
} from 'lucide-vue-next'
import LoadingDots from './LoadingDots.vue'
import { useSessionStore } from '@/store/session'
import { useMetaTags } from "@/components/useMetaTags"

const route = useRoute()
const router = useRouter()
const sessionStore = useSessionStore()

const sessionId = computed(() => route.query.session_id as string)

const fulfillPayment = async () => {
  if (!sessionId.value) {
    return
  }

  await sessionStore.fulfillPayment(sessionId.value)
}

const retryVerification = async () => {
  await fulfillPayment()
}

const redirectToHistory = () => {
  router.push('/history')
}

const redirectToPurchase = () => {
  router.push('/purchase')
}

const redirectToSupport = () => {
  window.location.href = 'mailto:support@kidstelltales.com'
}

onMounted(async () => {
  useMetaTags({
    title: 'Purchase Success',
    path: route.path,
  })

  if (!sessionStore.currentUser) {
    await sessionStore.fetchUser()
  }
  if (sessionStore.currentUser) {
    await fulfillPayment()
  }
})
</script>