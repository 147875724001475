<template>
  <Transition
      enter-active-class="transition-all duration-500 ease-out"
      enter-from-class="opacity-0 translate-y-full"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition-all duration-500 ease-out"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-full"
  >
    <div
        v-if="modelValue"
        class="fixed bottom-0 left-0 right-0 z-50 p-4 bg-slate-800/95 backdrop-blur-sm border-t border-slate-700/50"
    >
      <div class="max-w-6xl mx-auto">
        <div class="flex flex-col md:flex-row md:items-center justify-between gap-4">
          <div class="space-y-2">
            <h3 class="text-lg font-display font-semibold text-slate-100">Cookie Settings</h3>
            <p class="text-slate-300 text-sm">
              We use cookies to enhance your experience and analyze site usage.
            </p>
            <div>
              <button
                  @click="showDetails = !showDetails"
                  class="text-blue-400 hover:text-blue-300 text-sm underline flex items-center space-x-1 group"
              >
                <ChevronRight
                    class="h-4 w-4 transition-transform"
                    :class="{ 'rotate-90': showDetails }"
                />
                <span>{{ showDetails ? 'Hide cookie details' : 'Learn more' }}</span>
              </button>

              <!-- Details Section -->
              <div v-show="showDetails" class="pl-4 py-2 space-y-3 text-sm">
                <div class="space-y-3">
                  <div class="space-y-1">
                    <p class="text-slate-200 font-medium">Essential cookies</p>
                    <p class="text-slate-400">Required for the website to work properly - they keep you signed in and remember your preferences.</p>
                  </div>
                  <div class="space-y-1">
                    <p class="text-slate-200 font-medium">Analytics</p>
                    <p class="text-slate-400">Help us understand how people use our website and identify technical issues, so we can make it better for everyone.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col sm:flex-row gap-3">
            <button
                v-if="hasExistingConsent"
                @click="withdrawConsent"
                class="px-4 py-2 bg-red-600/20 hover:bg-red-600/30 border border-red-500/20
                      text-red-400 rounded-lg transition-colors flex items-center justify-center space-x-2"
            >
              <Trash2 class="h-4 w-4" />
              <span>Remove all cookies</span>
            </button>
            <button
                @click="essentialOnly"
                class="px-4 py-2 bg-slate-700 hover:bg-slate-600 text-slate-300 rounded-lg transition-colors"
            >
              Essential Only
            </button>
            <button
                @click="acceptAll"
                class="px-4 py-2 bg-blue-600 hover:bg-blue-500 text-white rounded-lg transition-colors"
            >
              Accept All
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { ChevronRight, Trash2 } from 'lucide-vue-next'
import { type CookiePreferences, getConsentPreferences, saveConsentPreferences } from '@/utils/cookie'
import { updateAnalyticsConsent, updateSentryConsent } from "@/utils/analytics"
import { storageManager } from "@/utils/storemanager"

const showDetails = ref(false)

const hasExistingConsent = computed(() => {
  const preferences = getConsentPreferences()
  return preferences.essential || preferences.analytics
})

defineProps<{
  modelValue: boolean
}>()

const emit = defineEmits<{
  'update:modelValue': [value: boolean]
}>()

function essentialOnly() {
  const preferences: CookiePreferences = {
    essential: true,
    analytics: false
  }

  storageManager.setConsent(true)
  updateAnalyticsConsent(false)
  updateSentryConsent(false)
  saveConsentPreferences(preferences)
  emit('update:modelValue', false)
}

function acceptAll() {
  const preferences: CookiePreferences = {
    essential: true,
    analytics: true
  }

  storageManager.setConsent(true)
  updateAnalyticsConsent(true)
  updateSentryConsent(true)
  saveConsentPreferences(preferences)
  emit('update:modelValue', false)
}

function withdrawConsent() {
  updateAnalyticsConsent(false)
  updateSentryConsent(false)
  storageManager.setConsent(false)

  window.location.reload()
}

onMounted(() => {
  const preferences = getConsentPreferences()
  if (!preferences.essential) {
    emit('update:modelValue', true)
  }
})
</script>