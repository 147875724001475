<template>
  <div v-if="sessionStore.error && !sessionStore.loading" class="m-4 p-4 bg-red-600/10 border border-red-500/20 rounded-lg" aria-live="polite">
    <div class="flex items-start space-x-3">
      <AlertTriangle class="h-5 w-5 text-red-400 flex-shrink-0 mt-0.5"/>
      <div class="space-y-2">
        <p class="text-red-400">
          <span v-if="sessionStore.error.statusCode === 404">This page does not exist.</span>
          <span v-else>Something went wrong. Please try again or/and refresh the page.</span>
        </p>
        <p class="text-sm text-red-300">
          If this issue persists, please check our
          <router-link to="/help" class="text-red-400 hover:text-red-300 underline">Help page</router-link>
          or contact support.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSessionStore } from '@/store/session'
import { AlertTriangle } from 'lucide-vue-next'

const sessionStore = useSessionStore()
</script>