<template>
  <div class=" p-6 space-y-8">
    <!-- Active Subscription State -->
    <div v-if="subscriptionInfo.hasActiveSubscription || isAlreadyPaid" class="text-center space-y-6">
      <CircleCheck class="h-12 w-12 mx-auto text-emerald-400"/>
      <div>
        <h2 class="text-xl font-display font-bold text-slate-100 mb-2">
          You Already Have an Active Plan
        </h2>
        <p class="text-slate-300">
          Your plan is valid until {{ formatDate(subscriptionInfo.subscriptionEndDate) }} and you still can have
          {{ subscriptionInfo.interactionsLeft }} AI interactions before reaching the limit for the period.
        </p>
      </div>
      <button
          @click="redirectToHistory"
          class="w-full px-6 py-4 bg-blue-600 hover:bg-blue-500
              text-white rounded-lg text-lg font-semibold
              transition-all duration-200
              flex items-center justify-center space-x-3
              group"
      >
        <BookOpen class="h-5 w-5 group-hover:scale-110 transition-transform"/>
        <span>Return to Stories</span>
      </button>
    </div>

    <template v-else>
      <!-- Header -->
      <div
          class="relative overflow-hidden rounded-xl bg-gradient-to-r from-blue-600/10 via-indigo-600/10 to-blue-600/10 p-6">
        <div class="absolute inset-0 bg-blue-500/5"></div>
        <div class="relative flex justify-between items-start">
          <div class="space-y-2">
            <h2 class="text-2xl font-display font-bold bg-gradient-to-r from-blue-400 to-indigo-400 bg-clip-text text-transparent">
              30-Day Plan
            </h2>
            <!-- Make interactions text clickable -->
            <button
                @click="showDetails = !showDetails"
                class="text-lg text-slate-300 hover:text-blue-400 transition-colors flex items-center space-x-2">
              <span>550 AI interactions limit</span>
              <ChevronDown
                  class="h-4 w-4 transition-transform duration-200"
                  :class="{ 'rotate-180': showDetails }"
              />
            </button>
          </div>
          <div class="text-right">
            <div class="text-3xl font-display font-bold text-slate-100">$7.99</div>
            <div class="text-sm text-slate-400">+ tax if applicable</div>
          </div>
        </div>

        <!-- Expandable Details Section -->
        <Transition
            enter-active-class="transition-all duration-200 ease-out"
            enter-from-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition-all duration-200 ease-in"
            leave-from-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 -translate-y-2"
        >
          <div v-show="showDetails" class="mt-8">
            <div class="text-center space-y-2">
              <p class="text-sm text-slate-300 text-left">
                That is:
              </p>
              <div class="relative grid grid-cols-3 gap-4 pt-2">
                <!-- Story options -->
                <div class="text-center space-y-1">
                  <div class="text-2xl">🐁</div>
                  <div class="text-slate-200 font-medium">110</div>
                  <div class="text-sm text-slate-400">short stories</div>
                </div>

                <!-- OR divider -->
                <div class="absolute left-1/3 top-1/2 -translate-y-1/2 -translate-x-1/2">
                  <div class="relative flex items-center justify-center w-8 h-8">
                    <div class="absolute inset-0 blur"></div>
                    <span class="relative text-sm font-medium text-slate-500">OR</span>
                  </div>
                </div>

                <div class="text-center space-y-1">
                  <div class="text-2xl">🐈</div>
                  <div class="text-slate-200 font-medium">78</div>
                  <div class="text-sm text-slate-400">medium stories</div>
                </div>

                <!-- OR divider -->
                <div class="absolute left-2/3 top-1/2 -translate-y-1/2 -translate-x-1/2">
                  <div class="relative flex items-center justify-center w-8 h-8">
                    <div class="absolute inset-0 blur"></div>
                    <span class="relative text-sm font-medium text-slate-500">OR</span>
                  </div>
                </div>

                <div class="text-center space-y-1">
                  <div class="text-2xl">🐘</div>
                  <div class="text-slate-200 font-medium">60</div>
                  <div class="text-sm text-slate-400">long stories</div>
                </div>
              </div>
            </div>
          </div>
        </Transition>

        <div class="mt-6">
          <div v-for="(benefit, index) in benefits"
               :key="index"
               class="flex items-start space-x-3 p-2 rounded-lg">
            <CheckCircle class="h-5 w-5 text-emerald-400 flex-shrink-0 mt-0.5"/>
            <span class="text-slate-300">{{ benefit }}</span>
          </div>
        </div>
      </div>

      <!-- More subtle interactions breakdown -->
      <div class="space-y-4">



        <div v-if="user" class="space-y-4 text-sm text-slate-300">
          <!-- Terms and Conditions Checkbox -->
          <label class="flex items-start space-x-3 cursor-pointer group">
            <div class="relative flex items-start">
              <div class="bg-slate-800/50 border border-slate-600/50 rounded w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 mt-0.5
                  group-hover:border-blue-500/50 transition-colors">
                <input
                    type="checkbox"
                    v-model="acceptTerms"
                    class="opacity-0 absolute cursor-pointer"
                >
                <svg
                    class="fill-current w-3 h-3 text-blue-400 pointer-events-none"
                    viewBox="0 0 20 20"
                    :class="{ 'hidden': !acceptTerms }"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                </svg>
              </div>
            </div>
            <span class="select-none">
      I declare that I have read the
      <a href="/terms" target="_blank" class="text-blue-400 hover:text-blue-300">Terms and Conditions</a> and
      <a href="/privacy" target="_blank" class="text-blue-400 hover:text-blue-300">Privacy Policy</a>
      and accept their provisions.
    </span>
          </label>

          <!-- Withdrawal Consent Checkbox -->
          <label class="flex items-start space-x-3 cursor-pointer group">
            <div class="relative flex items-start">
              <div class="bg-slate-800/50 border border-slate-600/50 rounded w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 mt-0.5
                  group-hover:border-blue-500/50 transition-colors">
                <input
                    type="checkbox"
                    v-model="acceptWithdrawal"
                    class="opacity-0 absolute cursor-pointer"
                >
                <svg
                    class="fill-current w-3 h-3 text-blue-400 pointer-events-none"
                    viewBox="0 0 20 20"
                    :class="{ 'hidden': !acceptWithdrawal }"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                </svg>
              </div>
            </div>
            <span class="select-none">
      I agree that the service provider may begin to provide the service before the withdrawal period.
      I further declare that I have been informed that after the service provider's performance
      I will lose my right to withdraw from the contract and I have taken note of this information.
    </span>
          </label>
        </div>

        <button
            @click="handleAction"
            :disabled="sessionStore.loading || !canProceed"
            class="w-full px-6 py-4 bg-blue-600 hover:bg-blue-500
        disabled:bg-blue-600/50 disabled:cursor-not-allowed
        text-white rounded-lg text-lg font-semibold
        transition-all duration-200
        flex items-center justify-center space-x-3
        group"
        >
          <span v-if="sessionStore.loading" class="flex items-center space-x-2">
            <span>Processing</span>
            <LoadingDots/>
          </span>
          <span v-else class="flex items-center space-x-2">
            <component
                :is="user ? CreditCard : LogIn"
                class="h-5 w-5 group-hover:scale-110 transition-transform"
            />
            <span>{{ user ? 'Pay securely with Stripe' : 'Create Account or Sign In to continue' }}</span>
          </span>
        </button>

      </div>

      <!-- Error Message -->
      <ErrorBox/>
    </template>
  </div>
</template>

<script setup lang="ts">
import {computed, onMounted, ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {useSessionStore} from '@/store/session'
import {BookOpen, CheckCircle, CircleCheck, CreditCard, LogIn, ChevronDown} from 'lucide-vue-next'
import LoadingDots from './LoadingDots.vue'
import ErrorBox from "@/components/ErrorBox.vue"
import {useSubscription} from '@/composables/useSubscription'
import {useMetaTags} from "@/components/useMetaTags"
import {useCurrentUser} from 'vuefire'

const router = useRouter()
const route = useRoute()
const sessionStore = useSessionStore()
const {subscriptionInfo, formatDate} = useSubscription()
const isAlreadyPaid = ref(false)
const user = useCurrentUser()
const showDetails = ref(false)
const acceptTerms = ref(false)
const acceptWithdrawal = ref(false)

const benefits = [
  'Create stories about any topic you love',
  'Let your child choose how each story unfolds',
  'Pick up right where you left off with saved stories',
  'Share magical adventures with friends',
  'Keep all your stories forever, even after your plan ends'
]

// Add this with other computed properties
const canProceed = computed(() => {
  if (!user.value) return true // Don't block the "Sign In" button
  return acceptTerms.value && acceptWithdrawal.value
})

const handleAction = async () => {
  if (!user.value) {
    router.push('/login')
    return
  }

  if (!sessionStore.currentUser) {
    await sessionStore.fetchUser()
  }

  if (subscriptionInfo.value.hasActiveSubscription) {
    return
  }

  const response = await sessionStore.createPayment(acceptTerms.value, acceptWithdrawal.value)

  if (response?.is_paid_already) {
    isAlreadyPaid.value = true
  } else if (response?.session_url) {
    window.location.href = response.session_url
  }
}

const redirectToHistory = () => {
  router.push('/history')
}

onMounted(async () => {
  useMetaTags({
    title: 'Purchase',
    path: route.path,
  })

  if (user.value && !sessionStore.currentUser) {
    await sessionStore.fetchUser()
  }
})
</script>