<template>
  <div class="min-h-screen bg-gradient-to-b from-slate-900 via-slate-800 to-slate-900 font-sans text-slate-300">
    <UserPanel/>

    <main class="pt-4 md:pt-12">
      <div class="max-w-3xl mx-auto">
        <div :class="[
          'bg-slate-800/50 backdrop-blur',
          'md:rounded-xl md:shadow-xl md:overflow-hidden',
          'md:border md:border-slate-700/50',
          'border-y border-slate-700/50 md:border-y-0',
          'md:px-6'
        ]">
          <router-view/>
        </div>
      </div>
    </main>

    <AppFooter @openCookieSettings="showCookieConsent = true"/>
    <CookieConsent v-model="showCookieConsent"/>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import UserPanel from "@/components/UserPanel.vue"
import CookieConsent from "@/components/CookieConsent.vue"
import AppFooter from "@/components/Footer.vue"

const showCookieConsent = ref(false)

</script>

<style>
/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&family=Inter:wght@400;500;600;700&display=swap');

/* Base styles */
:root {
  --font-sans: 'Inter', system-ui, -apple-system, sans-serif;
  --font-display: 'Nunito', var(--font-sans);
}

/* Typography */
.font-display {
  font-family: var(--font-display);
}

.font-sans {
  font-family: var(--font-sans);
}

/* Common text styles */
h1, h2, h3, h4, h5, h6 {
  @apply font-display font-bold text-slate-100;
}

h1 {
  @apply text-4xl md:text-5xl;
}

h2 {
  @apply text-3xl md:text-4xl;
}

h3 {
  @apply text-2xl md:text-3xl;
}

h4 {
  @apply text-xl md:text-2xl;
}

/* Button base styles */
button, .button {
  @apply transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed;
}

/* Primary button */
.btn-primary {
  @apply px-4 py-2 rounded-full bg-blue-600/10 border border-blue-500/20
  text-blue-400 hover:bg-blue-600/20 hover:border-blue-500/30
  active:bg-blue-600/30 focus:outline-none focus:ring-2
  focus:ring-blue-500/40 focus:ring-offset-2 focus:ring-offset-slate-900;
}

/* Secondary button */
.btn-secondary {
  @apply px-4 py-2 rounded-full bg-slate-800 border border-slate-600/20
  text-slate-300 hover:bg-slate-700 hover:text-white
  active:bg-slate-600 focus:outline-none focus:ring-2
  focus:ring-slate-500/40 focus:ring-offset-2 focus:ring-offset-slate-900;
}

/* Common card styles */
.card {
  @apply bg-slate-800/50 backdrop-blur border border-slate-700/50 rounded-xl shadow-xl overflow-hidden;
}

.card-header {
  @apply p-6 border-b border-slate-700/50;
}

.card-body {
  @apply p-6;
}

.card-footer {
  @apply p-4 border-t border-slate-700/50;
}

/* Form input styles */
input, textarea, select {
  @apply w-full px-4 py-2 rounded-lg bg-slate-800/50 border border-slate-600/50
  text-slate-300 placeholder-slate-500
  focus:border-blue-500/50 focus:ring-2 focus:ring-blue-500/20
  focus:outline-none transition-all duration-200;
}

/* Transitions */
.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-200;
}

.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}

/* Custom scrollbar for webkit browsers */
::-webkit-scrollbar {
  @apply w-2;
}

::-webkit-scrollbar-track {
  @apply bg-slate-900;
}

::-webkit-scrollbar-thumb {
  @apply bg-slate-700 rounded-full;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-slate-600;
}

/* Remove background color from autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #94a3b8;
  -webkit-box-shadow: 0 0 0px 1000px #1e293b inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* Base list styles */
ul, ol {
  @apply space-y-2;
}

/* Link styles */
a {
  @apply text-blue-400 hover:text-blue-300 transition-colors duration-200;
}

/* Loading animation */
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

/* Custom utilities */
.text-gradient {
  @apply bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-indigo-400;
}

.bg-gradient-subtle {
  @apply bg-gradient-to-b from-slate-800/50 to-slate-900/50;
}
</style>
