// src/utils/firebase.ts - create this new file for shared Firebase utilities
export function getFirebaseErrorMessage(error: any): string {
    // Get error code from Firebase error
    const errorCode = error?.code || 'unknown'

    // Map error codes to user-friendly messages
    switch (errorCode) {
        // Auth action code errors
        case 'auth/expired-action-code':
            return 'This link has expired. Please request a new one.'
        case 'auth/invalid-action-code':
            return 'This link is invalid or has already been used. Please log in or make the request again.'
        case 'auth/user-disabled':
            return 'This account has been disabled. Please contact support.'
        case 'auth/user-not-found':
            return 'No account found with this email. Please check your email or create a new account.'
        case 'auth/weak-password':
            return 'Password should be at least 6 characters long.'
        case 'auth/invalid-email':
            return 'Please enter a valid email address.'
        case 'auth/email-already-in-use':
            return 'An account with this email already exists. Please sign in instead.'
        case 'auth/wrong-password':
            return 'Incorrect password. Please try again or reset your password.'
        case 'auth/too-many-requests':
            return 'Too many unsuccessful attempts. Please try again later or reset your password.'
        case 'auth/network-request-failed':
            return 'Network error. Please check your internet connection and try again.'
        case 'auth/invalid-credential':
            return 'Invalid email or password. Please try again or try Google login'
        case 'auth/operation-not-allowed':
            return 'This operation is not allowed. Please contact support.'
        case 'auth/requires-recent-login':
            return 'This operation is sensitive and requires recent authentication. Please log in again.'
        default:
            console.error('Unhandled Firebase error:', error)
            return 'An unexpected error occurred. Please try again later.'
    }
}