<template>
  <div class="p-6 md:p-12">
    <div class="max-w-md mx-auto">
      <div class="p-6 space-y-6">
        <div class="text-center">
          <Mail class="h-12 w-12 mx-auto text-blue-400 mb-4"/>
          <h2 class="text-2xl font-display font-bold text-slate-100 mb-2">
            Verify Your Email
          </h2>
          <p class="text-slate-300 mb-6">
            Please check your email and click the verification link to activate your account.
          </p>

          <div class="space-y-4">
            <button
                @click="resendVerification"
                :disabled="isLoading"
                class="w-full p-4 bg-blue-600 hover:bg-blue-500 disabled:bg-blue-600/50
                     text-white rounded-lg font-medium
                     transition-all duration-200
                     flex items-center justify-center space-x-3
                     group"
            >
              <div v-if="isLoading" class="flex items-center space-x-2">
                <span>Sending</span>
                <LoadingDots/>
              </div>
              <div v-else class="flex items-center space-x-2">
                <Send class="h-5 w-5 group-hover:scale-110 transition-transform"/>
                <span>Resend Verification Email</span>
              </div>
            </button>

            <button
                @click="signOut"
                class="w-full p-4 bg-slate-800 hover:bg-slate-700
                     text-slate-300 hover:text-white rounded-lg font-medium
                     transition-all duration-200
                     flex items-center justify-center space-x-3
                     group"
            >
              <LogOut class="h-5 w-5 group-hover:scale-110 transition-transform"/>
              <span>Sign Out</span>
            </button>
          </div>
        </div>

        <!-- Error Message -->
        <div v-if="error"
             class="p-4 bg-red-600/10 border border-red-500/20 rounded-lg">
          <div class="flex items-start space-x-3">
            <AlertTriangle class="h-5 w-5 text-red-400 flex-shrink-0 mt-0.5"/>
            <p class="text-red-400 text-sm">{{ error }}</p>
          </div>
        </div>

        <!-- Success Message -->
        <div v-if="successMessage"
             class="p-4 bg-emerald-600/10 border border-emerald-500/20 rounded-lg">
          <div class="flex items-start space-x-3">
            <CheckCircle class="h-5 w-5 text-emerald-400 flex-shrink-0 mt-0.5"/>
            <p class="text-emerald-400 text-sm">{{ successMessage }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import {useCurrentUser, useFirebaseAuth} from 'vuefire'
import {sendEmailVerification, signOut as firebaseSignOut} from 'firebase/auth'
import {useRouter} from 'vue-router'
import LoadingDots from './LoadingDots.vue'
import {AlertTriangle, CheckCircle, LogOut, Mail, Send} from 'lucide-vue-next'

const user = useCurrentUser()
const auth = useFirebaseAuth()
const router = useRouter()
const error = ref('')
const successMessage = ref('')
const isLoading = ref(false)

async function resendVerification() {
  if (!user.value || isLoading.value) return

  isLoading.value = true
  error.value = ''
  successMessage.value = ''

  try {
    await sendEmailVerification(user.value)
    successMessage.value = 'Verification email sent! Please check your inbox.'
  } catch (e) {
    error.value = 'Failed to send verification email. Please try again later.'
    console.error('Verification error:', e)
  } finally {
    isLoading.value = false
  }
}

async function signOut() {
  try {
    await firebaseSignOut(auth)
    router.push('/login')
  } catch (e) {
    error.value = 'Failed to sign out. Please try again.'
  }
}
</script>