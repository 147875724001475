import './assets/main.css'

import { createApp } from 'vue'
import { initializeApp } from 'firebase/app'
import router from './router'

import App from './App.vue'

const preferences = getConsentPreferences()
storageManager.setConsent(preferences.essential)

const app = createApp(App)

const firebaseApp = initializeApp({
    apiKey: "AIzaSyDCS5qlFK2IPkLI7dMnujO7iqIGoX9pu-8",
    authDomain: "kidstelltales.firebaseapp.com",
    projectId: "kidstelltales",
    storageBucket: "kidstelltales.firebasestorage.app",
    messagingSenderId: "734796462707",
    appId: "1:734796462707:web:b1179865483c4774b68806",
    measurementId: "G-TNSYGVRGM7",

})

import { VueFire, VueFireAuth } from 'vuefire'
import {createPinia} from "pinia";
import * as Sentry from "@sentry/vue";
import {getConsentPreferences} from "@/utils/cookie";
import {initializeAnalytics, initializeSentry, updateAnalyticsConsent} from "@/utils/analytics";
import {storageManager} from "@/utils/storemanager";

app.use(VueFire, {
    firebaseApp: firebaseApp,
    modules: [
        VueFireAuth(),
    ],
})
app.use(router)
app.use(createPinia())

initializeAnalytics(firebaseApp)
initializeSentry({
    app,
    router,
    environment: import.meta.env.DEV ? 'development' : 'production'
})

app.mount('#app')