// src/utils/scroll.ts

interface ScrollOptions {
    top: number
    behavior: ScrollBehavior
}

function isReducedMotion(): boolean {
    return window.matchMedia('(prefers-reduced-motion: reduce)').matches
}

function isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

function smoothScroll(element: Window, options: ScrollOptions) {
    if (isReducedMotion()) {
        options.behavior = "auto"
    } else {
        options.behavior = "smooth"
    }

    if(!isSafari()) {
        element.scrollTo(options)
        return
    }

    const start = element.pageYOffset
    const startTime = performance.now()
    const duration = 500 // animation duration in ms

    function ease(t: number): number {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
    }

    function animate(currentTime: number) {
        const elapsed = currentTime - startTime
        const progress = Math.min(elapsed / duration, 1)

        element.scrollTo({
            top: start + (options.top - start) * ease(progress),
            behavior: 'auto'
        })

        if (progress < 1) {
            requestAnimationFrame(animate)
        }
    }

    requestAnimationFrame(animate)
}

export function scrollToElement(element: HTMLElement | null): void {
    if (!element) return

    const header = document.querySelector('nav') as HTMLElement
    const headerHeight = header?.offsetHeight || 0
    const offset = 16 // padding offset

    const rect = element.getBoundingClientRect()
    const elementTop = rect.top + window.pageYOffset
    const elementBottom = elementTop + rect.height
    const windowHeight = window.innerHeight

    const isFullyVisible = (
        rect.top >= headerHeight &&
        rect.bottom <= windowHeight
    )

    if (!isFullyVisible) {
        if (rect.height > windowHeight - headerHeight) {
            smoothScroll(window,{
                top: elementTop - headerHeight - 16,
                behavior: 'smooth'
            })
        } else {
            if (rect.top < headerHeight) {
                smoothScroll(window, {
                    top: elementTop - headerHeight - 16,
                    behavior: 'smooth'
                })
            } else if (rect.bottom > windowHeight) {
                smoothScroll(window,{
                    top: elementBottom - windowHeight + 16,
                    behavior: 'smooth'
                })
            }
        }
    }
}