import { computed } from 'vue'
import { useSessionStore } from '@/store/session'
import type { User } from '@/types/session'

export interface SubscriptionInfo {
    // Whether the user has an active subscription that allows story interactions
    hasActiveSubscription: boolean

    // Subscription period end date, formatted for display
    subscriptionEndDate: string

    // Number of interactions left in current period
    interactionsLeft: number

    // Human-readable subscription status message
    statusMessage: string

    // User object with subscription details
    user: User | null

    available: boolean

    neverHadSubscription: boolean
}

export function useSubscription() {
    const sessionStore = useSessionStore()

    const subscriptionInfo = computed<SubscriptionInfo>(() => {
        const user = sessionStore.currentUser
        if (!user) {
            return {
                hasActiveSubscription: false,
                subscriptionEndDate: '',
                interactionsLeft: 0,
                statusMessage: 'Not logged in',
                user: null,
                available: false,
                neverHadSubscription: true,
            }
        }

        const endDate = new Date(user.active_period.end_date)
        const now = new Date()
        const interactions = user.active_period.llm_responses_left

        const hasActiveSubscription = endDate > now && interactions > 0
        let statusMessage = ''

        const neverHad = (!sessionStore.currentUser?.active_period) || endDate.getTime() <= 0

        if(neverHad) {
            statusMessage = "You don't have an active subscription. Purchase now to create your own stories!"
        }
        else if (endDate <= now) {
            statusMessage = `Your subscription expired on ${formatDate(user.active_period.end_date)}. Renew now to continue your storytelling journey!`
        } else if (interactions <= 0) {
            statusMessage = 'You\'ve used all your story interactions. Get more to continue creating amazing stories!'
        } else {
            statusMessage = `You have ${interactions} story interactions remaining`
        }



        return {
            hasActiveSubscription,
            subscriptionEndDate: user.active_period.end_date,
            interactionsLeft: interactions,
            statusMessage,
            user,
            available: true,
            neverHadSubscription: neverHad,
        }
    })

    const formatDate = (dateString: string): string => {
        return new Intl.DateTimeFormat(navigator.language, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        }).format(new Date(dateString))
    }

    return {
        subscriptionInfo,
        formatDate
    }
}